import React, { useEffect, useRef, useState, useCallback } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import ImageViewer from "react-simple-image-viewer";
import app from "../Image/Slider (13).png";
import ai from "../Image/Slider (14).png";
import learning from "../Image/Slider (15).png";
import code from "../Image/Slider (2).png";
import compImg from "../Image/Slider (8).png";
import comp from "../Image/Slider (9).png";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const ParallaxAnimation = () => {
  const targetRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [fullscreen, setFullscreen] = useState(true);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const [openApplyModal, setOpenApplyModal] = useState(false);
  const [previewImgData, setPreviewImgData] = useState();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  // Adjust the y scrolling range based on viewport width

  const yRange =
    width > 1281
      ? ["1%", "-60%"]
      : width > 1023
      ? ["1%", "-75%"]
      : width > 767
      ? ["-95%", "1%"]
      : ["0%", "0%"];
  const x = useTransform(scrollYProgress, [0, 1], yRange);

  const images = [
    {
      img: app,
      txt: "HWforces",
      para: "HWForces is more than just software. it's your strategic partner in navigating the complexities of HR management. Powered by advanced technologies like AI and ML, HWForces delivers unparalleled efficiency and effectiveness",
      para1:
        "With a focus on user experience, HWForces offers seamless integration and intuitive interfaces. Say hello to features like Face Recognition, Internal Chatbot, and Video & Voice calls, revolutionizing the way you manage your workforce.",
      para2:
        "Experience the future of HR management with HWForces - Where Innovation Meets Productivity!",
    },
    {
      img: ai,
      txt: "ERP-Solutions",
      para: "Real-Time Inventory Visibility and Control: Centralized view of inventory levels across multiple warehouses/locations ,  Reduce excess inventory and optimize stock holding costs.",
      para1:
        "Streamlined Inventory Operations:  Integrated with procurement, production planning, and order fulfillment , Inventory Accuracy cycle counting and stock-taking procedures.",
      para2:
        "Analytics and Reporting for Smarter Decisions: Generate insightful reports on inventory valuation, stock levels, and more , Make data-driven decisions for better inventory management",
    },
    {
      img: learning,
      txt: "Institutional CRM",
      para: "Student Data Management: Effortlessly store and manage student information, including enrollment details, academic records, and contact information, ensuring data accuracy and accessibility.",
      para1:
        "Academic Process Automation: Streamline critical academic processes such as course registration, scheduling, grading, and attendance tracking, saving valuable time and resources for faculty and staff.",
      para2:
        "Communication Tools: Enhance communication channels with students, parents, and faculty through integrated email, SMS, and notification features, fostering seamless collaboration and engagement within the institution.",
    },
  ];

  const handleOpenPreview = (data) => {
    setOpenApplyModal(true);
    setPreviewImgData(data);
  };

  const closeModal = () => {
    setOpenApplyModal(false);
  };

  return (
    <>
      <div ref={targetRef} className="position-relative scroll-container ">
        <div
          className="position-sticky overflow-hidden scroll-x-div"
          style={{ top: 10 }}
        >
          <div className="container-lg mb-60">
            <div className="row  d-flex justify-content-between ">
              <div className="col-md-6 col-sm-12">
                <h1 className="fw-600 font-48 mb-3 mb-md-0">
                  {/* <span className="higlight">Customized solutions &nbsp;</span> */}
                  Customized solutions for every business need
                </h1>
              </div>
              <div className="col-md-6 col-sm-12 my-font">
                <p className="mt-md-2" style={{ textAlign: "justify" }}>
                  KSVSoftTech serves diverse industries including manufacturing,
                  banking, finance, insurance, education, healthcare, logistics,
                  retail & e-commerce, travel & hospitality, start-ups, media
                  & entertainment, providing tailored solutions that drive
                  growth and innovation.
                </p>
              </div>
            </div>
          </div>
          <div
            className={`d-flex ps-lg-5 display-none  bg-gray ${
              width < 1706 ? "p-60" : "pdVertical-140"
            }`}
          >
            <motion.div style={{ x }} className="d-flex gap-4">
              <div className="main">
                {images.map((src, index) => (
                  <img
                    src={src?.img}
                    onClick={() => handleOpenPreview(src)}
                    key={index + "_Img"}
                    alt="Card image cap"
                    className="card-img-top motion-img"
                  />
                ))}
              </div>
            </motion.div>
          </div>

          <div className="container-lg d-md-none d-sm-block">
            <div className="row  text-center d-flex align-items-stretch">
              <Splide
                options={{
                  perPage: 3,
                  gap: "10px",
                  pagination: false,
                  fixedWidth: "33.33%",
                  breakpoints: {
                    767: {
                      perPage: 1, // Adjust the number of slides per page for smaller screens
                      gap: "0.8rem", // Reduce the gap between slides on smaller screens
                      fixedWidth: "98%", // Adjust the width of slides for smaller screens
                    },
                    1280: {
                      perPage: 3, // Adjust the number of slides per page for 1280px screens
                      gap: "0.5rem", // Adjust the gap between slides for 1280px screens
                      fixedWidth: "33.33%", // Adjust the width of slides for 1280px screens
                    },
                    768: {
                      perPage: 2, // Adjust the number of slides per page for 1280px screens
                      gap: "1rem", // Adjust the gap between slides for 1280px screens
                      fixedWidth: "50%", // Adjust the width of slides for 1280px screens
                    },
                  },
                }}
                aria-label="My Favorite Images"
              >
                <SplideSlide>
                  <button
                    type="button"
                    className="parallax"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <img
                      className="card-img-top motion-img img-fluid"
                      src={app}
                      alt="Card image cap"
                    />
                  </button>
                </SplideSlide>

                <SplideSlide>
                  <img
                    className="card-img-top motion-img img-fluid"
                    src={ai}
                    alt="Card image cap"
                  />
                </SplideSlide>

                <SplideSlide>
                  <img
                    className="card-img-top motion-img img-fluid"
                    src={learning}
                    alt="Card image cap"
                  />
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </div>

      <Modal show={openApplyModal} onHide={closeModal} fullscreen={fullscreen}>
        <Modal.Header closeButton>
          {
            <Modal.Title>
              <h6 className="fw-600">{previewImgData?.txt}</h6>
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <div className="row modal-scroll d-flex justify-content-center modal-padding">
            <div className="col-md-8 text-center">
              <img className="modal-image" src={previewImgData?.img} />
            </div>
            <div className="col-md-4 solution-modal">
              <ul>
                <h1 className="font-36 fw-600 higlight text-center">
                  {" "}
                  {previewImgData?.txt}{" "}
                </h1>
                <li> {previewImgData?.para} </li>
                <li> {previewImgData?.para1} </li>
                <li> {previewImgData?.para2} </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ParallaxAnimation;
